import { Injectable } from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import jwt_decode from 'jwt-decode';
// import { environment } from 'src/environments/environment';
import { AppConfig } from '../../core/app.config';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public appDrawer: any;
  public title: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentUrl = new BehaviorSubject<string>(undefined);
  public showProgressLoader = new BehaviorSubject<boolean>(true);
  public showHiddenOverlay = new BehaviorSubject<boolean>(false);
  public showBellowHeader = new BehaviorSubject<boolean>(true);
  public enableViewPort = new BehaviorSubject<boolean>(true);
  public isLoginPage = new BehaviorSubject<boolean>(false);
  public userName = new BehaviorSubject<string>('');
  public userRoleId = new BehaviorSubject<number>(0);
  public vehicleId: number;
  public loggedUserId: number;
  public filteredVehicleList: {}[];
  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private appConfig: AppConfig
  ) { 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }
  secret = this.appConfig.getConfig("secret");
  setIsLoginPage(value: boolean) {
    this.isLoginPage.next(value);
  }

  loadUserName() {
    const token = localStorage.getItem('token');
    if (!token || token.length === 0) return;
    const decoded = jwt_decode(token);
    this.loggedUserId = decoded.userId;
    this.setUserName(decoded.userName);
  }

  get getLoggedUserId() {
    return this.loggedUserId;
  }

  loadUserRole() {
    const token = localStorage.getItem('token');
    if (!token || token.length === 0) return;
    const decoded = jwt_decode(token);
    this.setUserRole(decoded.roleId);
  }

  setUserName(value) {
    this.userName.next(value);
  }

  setUserRole(value) {
    this.userRoleId.next(value);
  }

  getUserRoleId() {
    const token = localStorage.getItem('token');
    if (!token || token.length === 0) return;
    const decoded = jwt_decode(token);
    return decoded.roleId;
  }
  
  setHeaderTitle(value: string) {
    this.title.next(value);
  }

  setProgressLoader(value) {
    this.showProgressLoader.next(value);
  }

  setHiddenOverlay(value) {
    this.showHiddenOverlay.next(value);
  }

  setBellowHeader(value) {
    this.showBellowHeader.next(value);
  }

  setViewPort(value) {
    this.enableViewPort.next(value);
  }

  setVehicleId(id) {
    this.vehicleId = id;
  }

  getVehicleId() {
    return this.vehicleId;
  }

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

  transformDate(date, format: string) {
    return this.datePipe.transform(date, format);
  }

  setFilteredVehicleList(filteredVehicleList) {
    this.filteredVehicleList = filteredVehicleList;
  }

  getFilteredVehicleList() {
   return this.filteredVehicleList;
  }

  getPasswordString(name,secret = this.secret) {
    let d = new Date();
    let date = this.addZero(d.getDate())+""+this.addZero((d.getMonth()+1))+""+d.getFullYear();
    let final="";
    let length = (name.length > secret.length) ? name.length : secret.length;
    name = name.split("");
    let splitedSecret = secret.split("");
    let splitedDate = date.split("");
      for(let i=0; i < length ;i++){
        final += this.isUndefined(splitedDate[i])+""+this.isUndefined(name[i])+""+this.isUndefined(splitedSecret[i]);
      }
      return final;
  }

  isUndefined(val) {
    if (val == undefined) {
      return '';
    }
    return val;
  }

  addZero(value) {
   if (`${value}`.length === 1) {
     return `0${value}`;
   }
   return value;
  }
    

}
