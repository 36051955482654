import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { 
  AuthGuardService as AuthGuard 
} from './core/guards/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/login/login.module#LoginModule',
    pathMatch: 'full'
  },
  {
    path: 'vehicleList', canLoad: [AuthGuard],
    loadChildren: './modules/vehicle-list/vehicle-list.module#VehicleListModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [2]
    } 
  },{
    path: 'vehicleRegister', canLoad: [AuthGuard],
    loadChildren: './modules/vehicle-registration-page/vehicle-registration-page.module#VehicleRegistrationPageModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [2]
    } 
  },
  {
    path: 'history', canLoad: [AuthGuard],
    loadChildren: './modules/vehicle-list/vehicle-list.module#VehicleListModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [2, 3]
    } 
  },
  {
    path: 'periodDuration', canLoad: [AuthGuard],
    loadChildren: './modules/period-duration/period-duration.module#PeriodDurationModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [2]
    } 
  },{
    path: 'registrationDuplication', canLoad: [AuthGuard],
    loadChildren: './modules/registration-duplication/registration-duplication.module#RegistrationDuplicationModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [2]
    } 
  },{
    path: 'approvalList', canLoad: [AuthGuard],
    loadChildren: './modules/approval-list/approval-list.module#ApprovalListModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [3]
    } 
  },{
    path: 'qrmanagement', canLoad: [AuthGuard],
    loadChildren: './modules/qr-management/qr-management.module#QrManagementModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [3]
    } 
  },{
    path: 'usermanagement', canLoad: [AuthGuard],
    loadChildren: './modules/user-management/user-management.module#UserManagementModule',
    pathMatch: 'full',
    data: { 
      expectedRole: [1]
    } 
  },{
    path: 'login',
    loadChildren: './modules/login/login.module#LoginModule', 
    pathMatch: 'full'
  },{
    path: '**',
    redirectTo: 'login', 
    pathMatch: 'full'
  }
  // {
  //   path: '',
  //   component: HomeComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
