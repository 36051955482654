import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {Router} from '@angular/router';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    
    constructor(
        public router: Router
    ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customReq = request.clone({
    });
    
    return next
      .handle(customReq)
      .do((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          if (ev.body['statusCode'] == '0' && ev.body['statusMessage'].startsWith('io.jsonwebtoken.Expired')) {
            localStorage.clear();
                  this.router.navigate(['/login']);
                  return;
          }
        }
      })
        .catch(response => {
          if (response instanceof HttpErrorResponse) {
              if (
                response.status === 401 
                && response.statusText == 'Unauthorized'
                || response['statusCode'] == '0'
                && response['statusMessage'].startsWith('Unauthorized')
                || response['statusCode'] == '0'
                && response['statusMessage'].startsWith('io.jsonwebtoken.Expired')){
                  localStorage.clear();
                  this.router.navigate(['/login']);
                  return;
              }
          }

          return Observable.throw(response);
        });
  }
}