export const environment = {
  production: true,
  apiUrl: 'https://pixel-gateway-sit.olamghanadigital.com/',
  imageRenderUrl:'https://pixelsitscs.olamghanadigital.com/storageconnect/api/v1/common/',
  clientID: '499268ce-47df-4abb-9b85-b713b6b712fc',
  tenentID: '5e007b6c-258b-4fde-adc1-8bf8a135885d',
  authority: 'https://login.microsoftonline.com/',
  redirectUri: 'https://pixel-portal-sit.ofighanadigital.com/',
  secret : 'jGb8Q~1QMjCU1RHOIFGnixf-2bmX6wxVFVhb~dso'
};
