import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 'app-internet-unavailable',
  templateUrl: './internet-unavailable.component.html',
  styleUrls: ['./internet-unavailable.component.scss']
})
export class InternetUnavailableComponent implements OnInit {

  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.utilityService.setProgressLoader(false);
  }

}
