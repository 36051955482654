import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Safe } from '../core/pipes/safeHtml';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutModule } from '@angular/cdk/layout';
import { ProceedComponent } from './dialogs/proceed/proceed.component';
import { SuccessComponent } from './dialogs/success/success.component';
import { TimeslotComponent } from './dialogs/timeslot/timeslot.component';
import { RegDuplicationPopupComponent } from './dialogs/reg-duplication-popup/reg-duplication-popup.component';
import { UserPopupComponent } from './dialogs/user-popup/user-popup.component';
import { QrScannerComponent } from './dialogs/qr-scanner/qr-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DupVehicleVendorFilterPipe } from '../core/pipes/dup-vehicleVendor-filter.pipe';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LayoutModule,
    ZXingScannerModule
    
  ],
  declarations: [
    Safe,
    ProceedComponent,
    SuccessComponent,
    TimeslotComponent,
    RegDuplicationPopupComponent,
    UserPopupComponent,
    QrScannerComponent,
    DupVehicleVendorFilterPipe
  ],
  exports: [
    Safe
    ],
  entryComponents: [
    ProceedComponent,
    SuccessComponent,
    TimeslotComponent,
    RegDuplicationPopupComponent,
    UserPopupComponent,
    QrScannerComponent
  ]
})
export class SharedModule { }
