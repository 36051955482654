import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiService } from '../../../core/services/api.service';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 'app-reg-duplication-popup',
  templateUrl: './reg-duplication-popup.component.html',
  styleUrls: ['./reg-duplication-popup.component.scss']
})
export class RegDuplicationPopupComponent implements OnInit {
  public showHiddenOverlay = false;
  
  public seasons; 
  public showToSeasonList = false;
  public selectedToSeason = '';
  public showFromSeasonList = false;
  public selectedFromSeason = '';

  public vendors: {}[] = [];
  public showVendorList = false;
  public selectedVendor = '';
  
  public masterVehicleList: [] = [];
  public showVehicleList = false;
  public selectedVehicleListString = '';
  public vendorBasedVehicleList = [];
  public selectedVendorBasedVehicleList = [];

  public form;
  public submited = false;

  public selectToPeriodDuration;
  public selectFromPeriodDuration;
  public selectVendor;
  public selectedVehicleAtAddMoreState = [];
  public currentDate = new Date();
  public searchedVendorModel;
  public searchedVehicleModel;
  public objectSentToComponent: {
    fromSeason: {};
    toSeason: {};
    selectedvendor: {};
    selectedvehicleList: {};
  }
  addMoreState: boolean;

  constructor(
    public dialog: MatDialogRef<RegDuplicationPopupComponent>,
    public apiService : ApiService,
    public utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getSeasonList();
    this.form = new FormGroup({
      toseason: new FormControl('', Validators.required),
      fromseason: new FormControl('', Validators.required),
      vendor: new FormControl('', Validators.required),
      vehicle: new FormControl('', Validators.required),
     });
     if (this.data) {
      this.addMoreState = true;
    this.selectedToSeason = `${this.utilityService.transformDate(this.data.toSeason['startingPeriod'], 'MMM yyyy')} - ${this.utilityService.transformDate(this.data.toSeason['endingPeriod'], 'MMM yyyy')}`;
    this.selectedFromSeason = `${this.utilityService.transformDate(this.data.fromSeason['startingPeriod'], 'MMM yyyy')} - ${this.utilityService.transformDate(this.data.fromSeason['endingPeriod'], 'MMM yyyy')}`;
    this.selectedVendor = this.data.selectedvendor.name;
    const temp = [...this.data.selectedvehicleList]
    this.selectedVehicleAtAddMoreState = temp;
      this.toSeasonSelecthandler(this.data.toSeason);
      this.fromSeasonSelecthandler(this.data.fromSeason);
      this.formPatchingValue('toseason', this.data.toSeason);
      this.formPatchingValue('fromseason', this.data.fromSeason);
      this.formPatchingValue('vendor', this.data.selectedvendor);
     }
  }

  get f() { return this.form.controls; }

  nextClickHandler() {
    this.submited = true;
    if (this.form.invalid) {
      return;
    }

    this.objectSentToComponent = {
      fromSeason: this.selectFromPeriodDuration,
      toSeason: this.selectToPeriodDuration,
      selectedvendor: this.selectVendor,
      selectedvehicleList: this.addMoreState ? this.selectedVehicleAtAddMoreState : this.selectedVendorBasedVehicleList
    }
    this.dialog.close(this.objectSentToComponent);
  }

  overlayClickHandler() {
    this.showHiddenOverlay = false;
    this.showToSeasonList = false;
    this.showFromSeasonList = false;
    this.showVendorList = false;
    this.showVehicleList = false;
    this.searchedVendorModel = '';
    this.searchedVehicleModel = '';
  }

  getSeasonList() {
    this.apiService.callGetAPI('seasonManagement/findAll').subscribe(res => {
      this.seasons = res['seasonList'];
      this.seasons = this.seasons.filter(val => {
        if (new Date(val['endingPeriod']) >= this.currentDate) {
          return val;
        }
      });
  });
  }

  toSeasonClickHandler() {
    this.showHiddenOverlay = true;
    this.showToSeasonList = !this.showToSeasonList;
  }
  toSeasonSelecthandler(toSeason) {
    this.selectToPeriodDuration = toSeason;
    this.selectedToSeason = `${this.utilityService.transformDate(toSeason['startingPeriod'], 'MMM yyyy')} - ${this.utilityService.transformDate(toSeason['endingPeriod'], 'MMM yyyy')}`;
    this.formPatchingValue('toseason', this.selectedToSeason);
    this.overlayClickHandler();
  }


  fromSeasonClickHandler() {
    this.showHiddenOverlay = true;
    this.showFromSeasonList = !this.showFromSeasonList;
  }
  fromSeasonSelecthandler(fromSeason) {
    this.selectFromPeriodDuration = fromSeason;
    this.selectedFromSeason = `${this.utilityService.transformDate(fromSeason['startingPeriod'], 'MMM yyyy')} - ${this.utilityService.transformDate(fromSeason['endingPeriod'], 'MMM yyyy')}`;
    this.formPatchingValue('fromseason', this.selectedFromSeason);    
    this.overlayClickHandler();
    this.selectedVendor = '';
    this.formPatchingValue('vehicle', '');    
    this.formPatchingValue('vendor', '');  
    this.selectedVehicleListString = '';
    this.selectedVendorBasedVehicleList = [];  
    this.getVehicleList(fromSeason.seasonID);    
  }

  getVendorListFromVehicleList() {
    this.vendors = this.masterVehicleList.map(val => val['vendor']);
    this.vendors = this.getUnique(this.vendors, 'vendorId');
  }

  vendorClickHandler() {
    this.showHiddenOverlay = true;
    this.showVendorList = !this.showVendorList;
  }
  vendorSelecthandler(vendor) {
    this.selectVendor = vendor;
    this.selectedVendor = `${vendor.name} - ${vendor.vendorCode}`;
    this.searchedVendorModel = '';
    this.formPatchingValue('vendor', this.selectedVendor);
    this.vendorBasedVehicleList = this.masterVehicleList.filter(val => val['vendor']['vendorId'] === vendor.vendorId);
    this.selectedVehicleListString = '';
    this.selectedVendorBasedVehicleList = [];
    this.overlayClickHandler();
  }

  getVehicleList(seasonId) {
    this.apiService.callPostAPI('vehicleManagement/getVehicleBySeasonId',+seasonId).subscribe(data => {
      this.masterVehicleList = data['vehicleList'];
      this.getVendorListFromVehicleList();
      if (this.addMoreState) {
        this.vendorSelecthandler(this.data.selectedvendor);
        this.selectedVendorBasedVehicleList = this.data.selectedvehicleList;
        this.generateVehicleNumberString(this.data.selectedvehicleList);
      }
    });
  }

  vehicleClickHandler() {
    this.showHiddenOverlay = true;
    this.showVehicleList = !this.showVehicleList;
  }

  vehicleSelecthandler(e, vehicle) {
    if (e.srcElement.checked) {
      this.addToVehicleArray(vehicle);
    } else {
      this.removeFromVehicleArray(vehicle);
    }
  }

  addToVehicleArray(vehicle) {
    if (this.addMoreState) {
      this.selectedVehicleAtAddMoreState.push(vehicle);
      this.generateVehicleNumberString(this.selectedVehicleAtAddMoreState);

    } else {
    this.selectedVendorBasedVehicleList.push(vehicle);
    this.generateVehicleNumberString(this.selectedVendorBasedVehicleList);

    }
  }

  removeFromVehicleArray(vehicle) {
    if (this.addMoreState) {
      this.selectedVehicleAtAddMoreState.splice(
        this.selectedVehicleAtAddMoreState.findIndex(val => val.vehicleId === vehicle.vehicleId), 1
        );
        this.generateVehicleNumberString(this.selectedVehicleAtAddMoreState);
    } else {
    this.selectedVendorBasedVehicleList.splice(
    this.selectedVendorBasedVehicleList.findIndex(val => val.vehicleId === vehicle.vehicleId), 1
    );
    this.generateVehicleNumberString(this.selectedVendorBasedVehicleList);
  }
  }

  generateVehicleNumberString(selectedVehicleList) {
    const vehicleNumberOnlyArray = selectedVehicleList.map(val => val.vehicleNumber);
    this.selectedVehicleListString = vehicleNumberOnlyArray.toString();
    this.formPatchingValue('vehicle', this.selectedVehicleListString);    
  }

  isVehicleChecked(vehicle) {
    if (this.addMoreState) {
      return this.selectedVehicleAtAddMoreState.find(val => val.vehicleNumber === vehicle.vehicleNumber);
    } else {
      return this.selectedVendorBasedVehicleList.find(val => val.vehicleNumber === vehicle.vehicleNumber);
    }
  }

  getUnique(arr, comp) {
    const unique = arr
         .map(e => e[comp])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
     return unique;
  }

  formPatchingValue(key, value) {
    this.form.patchValue({
      [key] : value
    });
  }

  getFiveSeasonsLessThanToSeason(seasons) {
    if (this.selectToPeriodDuration) {
      let FiveSeasons = seasons.filter(val => new Date(val.endingPeriod) < new Date(this.selectToPeriodDuration.endingPeriod));
      return FiveSeasons.slice(0,5);
    } else {
      return [];
    }
  }

  closeClicked() {
    this.dialog.close(this.addMoreState ? this.data : 'close');
  }

}
