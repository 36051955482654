import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {
  public scannerEnabled = false;
  public deviceList;
  public capturingDeviceFound= false;
  public qrCodeNumber;
  public desiredDevice = null;
  public selectedCameraName = '';
  public showDeviceList = false;
  showHiddenOverlay: boolean;
  constructor(
    public dialog: MatDialogRef<QrScannerComponent>,
  ) { }

  ngOnInit() {
    this.scannerEnabled = true;
  }

  camerasFoundHandler(e) {
    if (e) {      
      this.deviceList = e;
      if (this.deviceList.length > 0) {
        setTimeout(() => {
          this.desiredDevice = this.deviceList[0];
        }, 1000);
        this.selectedCameraName = this.deviceList[0].label;
        this.capturingDeviceFound = true;
      } else {
        this.desiredDevice = null;
        this.selectedCameraName = 'Device Not Found';
        this.capturingDeviceFound = false;
      }
      
    }
  }
  camerasNotFoundHandler(e) {
    if (e) {
      this.capturingDeviceFound = false;
    }
  }

  scanSuccessHandler(e) {
    if (e) {
      this.dialog.close(e);
    }
  }

  showCameraList() {
    this.showDeviceList = true;
    this.showHiddenOverlay = true;
  }

  overlayClickHandler() {
    this.showDeviceList = false;
    this.showHiddenOverlay = false;
  }

  deviceSelecthandler(device) {
    this.selectedCameraName = device.label;
    this.desiredDevice = device;
    this.overlayClickHandler();
  }

}
