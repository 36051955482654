import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UtilityService } from '../../../core/services/utility.service';
import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-user-popup',
  templateUrl: './user-popup.component.html',
  styleUrls: ['./user-popup.component.scss']
})
export class UserPopupComponent implements OnInit {
  public registerForm: FormGroup;;
  public showHiddenOverlay = false;
  public showUserList = false;
  public roleList = [];
  public submitted;
  public isEmailDuplicate = false;
  public emailDuplicateMsg = '';
  public checkDuplicate = false;
  constructor(
    public dialog: MatDialogRef<UserPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService,
    private formBuilder: FormBuilder,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.apiService.callGetAPI('userManagement/role/findAll').subscribe(res => {
      this.roleList = res['roleList'];
    });
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      useremailid: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      id: [''],
      deleted: [''],
      userCredential: [''],
      status: [1]
  });
  if (this.data.userData) {
  this.registerForm.patchValue({
    firstName: this.data.userData.firstName,
    lastName: this.data.userData.lastName,
    useremailid: this.data.userData.useremailid,
    role:  this.data.userData.role.roleName
  });
  this.registerForm.valueChanges.subscribe(data => {
    if (data.useremailid != this.data.userData.useremailid) {
      this.checkDuplicate = true;
    } else {
      this.checkDuplicate = false;
    }
  });
}
  }

  overlayClickHandler() {
    this.showUserList = false;
    this.showHiddenOverlay = false;
  }

  get f() { return this.registerForm.controls; }

  showUserListHandler() {
    this.showUserList = true;
    this.showHiddenOverlay = true;
  }

  checkUserNameIsDuplicateOrNot(email) {
    this.apiService.callGetAPI(`userManagement/user/verifyEmailId?emailId=${email}`).subscribe(data => {
      if (data['status'] == 0) {
        this.isEmailDuplicate = true;
        this.emailDuplicateMsg = data['errorMessage']; 
      } else {
        this.isEmailDuplicate = false;
        this.populateDataAndClose();
      }
    })
  }

  addUser() {
    this.submitted = true;
        this.registerForm.valueChanges.subscribe(data => {
          this.isEmailDuplicate = false;
        });
        if (this.registerForm.invalid) {
            return;
        }
        if (this.data.userData) {
          if (this.checkDuplicate) {
          this.checkUserNameIsDuplicateOrNot(this.registerForm.value.useremailid);    
          } else {
          this.populateDataAndClose();
        }
        } else {
          this.checkUserNameIsDuplicateOrNot(this.registerForm.value.useremailid);    
        }
  }

  populateDataAndClose() {
    this.registerForm.patchValue({
      userCredential: this.utilityService.getPasswordString(this.registerForm.value.useremailid)
    });

    if (this.data.userData) {
      this.registerForm.patchValue({
        id: this.data.userData.id,
        deleted: this.data.userData.deleted,
        userCredential: this.data.userData.userCredential || this.utilityService.getPasswordString(this.registerForm.value.useremailid),
        status:  this.data.userData.status
      });
    }


    this.dialog.close({
      which : this.data.userData ? 'edit' : 'add',
      formData : this.registerForm.value
    })
  }

  roleSelectHandler(role: string) {
    this.registerForm.patchValue({
      role : role
    });
    this.overlayClickHandler();
  }

}
