import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dupvehicleVendorfilter'
})
export class DupVehicleVendorFilterPipe implements PipeTransform {

  transform(value: any, args?: any, property?: any): any {
    if (args) {
      if (property)
      return value.filter(val => val[property].toLowerCase().indexOf(args.toLowerCase()) !== -1);
      else
      return value.filter(val => val.toLowerCase().indexOf(args.toLowerCase()) !== -1);
    } else {
      return value;
    }
  }
}
