import { Component, OnInit, OnDestroy } from '@angular/core';

import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 'app-hidden-overlay',
  templateUrl: './hidden-overlay.component.html',
  styleUrls: ['./hidden-overlay.component.scss']
})
export class HiddenOverlayComponent implements OnInit, OnDestroy {
  showOverlay: boolean;
  subscription;
  constructor(
    public utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.subscription = this.utilityService.showHiddenOverlay.subscribe(val => this.showOverlay = val);
  }

  hiddenOverlaClicked() {
    this.utilityService.setHiddenOverlay(false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
