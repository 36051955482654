import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiService } from '../../../core/services/api.service';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss']
})
export class TimeslotComponent implements OnInit {
  public form;
  public submitted = false;
  public showMonthList = false;
  public showYearList = false;
  public startMonthVal = null;
  public startYearVal = null;
  public endMonthVal = null;
  public endYearVal = null;
  public clicked: string;
  public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public showHiddenOverlay = false;
  public showNotMatchError = false;
  public currentDate;
  public startyear;
  public temp = false;
  public isDuplicateMsg = '';
  public isDuplicate = false;
  public checkDuplicate = false;


  constructor(
    public dialog: MatDialogRef<TimeslotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService
  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    this.startyear = this.currentDate.getFullYear()
    this.form = new FormGroup({
      organaizationName: new FormControl('GH19', Validators.required),
      startMonth: new FormControl('', Validators.required),
      startYear: new FormControl('', Validators.required),
      endMonth: new FormControl('', Validators.required),
      endYear: new FormControl('', Validators.required)
     });
    this.populateStartWithCurrentDate();
     if (this.data.season) {
      const startDate = new Date(`${this.data.season.startingPeriod}`);
      const endDate = new Date(`${this.data.season.endingPeriod}`);
     this.form.patchValue({
      organaizationName: this.data.season.region.toUpperCase(),
      startMonth: startDate.getMonth(),
      startYear: startDate.getFullYear(),
      endMonth: endDate.getMonth(),
      endYear: endDate.getFullYear()
    });
    
    this.startMonthVal = this.months[startDate.getMonth()];
    this.startYearVal = startDate.getFullYear();
    this.endMonthVal = this.months[endDate.getMonth()];
    this.endYearVal = endDate.getFullYear();
    this.form.valueChanges.subscribe(data => {
        if (data.organaizationName != this.data.season.region) {
          this.checkDuplicate = false;
        } else {
          this.checkDuplicate = true;
        }
    })
  }
  }

  populateStartWithCurrentDate() {
    this.startMonthVal = this.months[this.currentDate.getMonth()];
    this.startYearVal = this.currentDate.getFullYear();
    this.form.patchValue({
      startMonth: this.months.indexOf(this.months[this.currentDate.getMonth()]),
      startYear: this.currentDate.getFullYear(),
    });
  }

  get getMonths() {
    if (this.clicked === 'startMonthVal' && this['startYearVal'] === this.currentDate.getFullYear()) {
      return this.months.slice(this.currentDate.getMonth(), this.months.length);
    }
    if (this.clicked === 'endMonthVal' && this.startYearVal === this.endYearVal) {
      return this.months.slice(this.months.indexOf(this.startMonthVal)+1, this.months.length);
    }
    return this.months;
  }

  blockSpecialChar(e){
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  get f() { return this.form.controls; }

  onSubmit(){
    this.submitted = true;
        if (this.form.invalid) {
            return;
        }
    
        const toServer: any = {};
    const startDate = new Date(0);
    
    startDate.setFullYear(this.startYearVal);
    startDate.setMonth(this.months.findIndex((val) => val === this.startMonthVal));

    const endDate = new Date(0);
    
    endDate.setFullYear(this.endYearVal);
    endDate.setMonth(this.months.findIndex((val) => val === this.endMonthVal));
    toServer.startingPeriod = startDate;
    toServer.endingPeriod = endDate;
    toServer.seasonID = this.data.season ? this.data.season.seasonID : 0;
    if (startDate.getTime() > endDate.getTime()) {
      this.showNotMatchError = true;
      return;
    } else {
      this.showNotMatchError = false;
    }
    this.form.patchValue({
      organaizationName: this.form.value.organaizationName.toUpperCase()
    });
      this.form.valueChanges.subscribe(data => {
        this.isDuplicate = false;
      });
       if (this.data.season) {
    if (this.checkDuplicate) {
      this.verifyPeriodDuration(toServer);
    } else {
      this.populateAndCloseDialog();
    }
  } else {
    this.verifyPeriodDuration(toServer);
  }

        
  }

  verifyPeriodDuration(toServer) {
    this.apiService.callPostAPI(`seasonManagement/verifySeasonPeriod`, toServer).subscribe(data => {
      if (data['statusCode'] == 0) {
        this.isDuplicate = true;
        this.isDuplicateMsg = data['statusMessage'];
      } else {
        this.isDuplicate = false;
        this.populateAndCloseDialog();
      }
    });
  }

  populateAndCloseDialog() {
    if (typeof this.form.value.endMonth === 'string' || typeof this.form.value.startMonth === 'string') {
    this.form.value.endMonth = this.months.findIndex((val) => val === this.form.value.endMonth);
    this.form.value.startMonth = this.months.findIndex((val) => val === this.form.value.startMonth);
  }
    const data = this.form.dirty ? {
      seasonID: this.data.season ? this.data.season.seasonID : 0,
      formData: this.form.value
    } : null;
    this.dialog.close(data);
  }

  openMonthList(which: string) {
    this.clicked = which;
    this.showMonthList = true;
    this.showHiddenOverlay = true;
  }

  openYearList(which: string) {
    if (which === 'startYearVal') {
      this.startyear = this.currentDate.getFullYear();
    }
    this.clicked = which;
    this.showYearList = true;
    this.showHiddenOverlay = true;
  }

  overlayClickHandler() {
    this.showMonthList = false;
    this.showHiddenOverlay = false;
    this.showYearList = false;
  }

  selectedFromList(value) {
    this.form.markAsDirty();
    this[this.clicked] = value; 
    this.overlayClickHandler();
    this.form.patchValue({
      [this.clicked.substring(0, this.clicked.length - 3)]: typeof this[this.clicked] === 'string' ? this.months[this.months.findIndex(val => val === this[this.clicked])] : this[this.clicked],
    });
    if (this.clicked === 'startYearVal' && (this.startYearVal === this.currentDate.getFullYear() && this.months.indexOf(this.startMonthVal) < this.currentDate.getMonth())) {
      this.startMonthVal = '';
      this.form.patchValue({
        startMonthVal: ''
      });
    }
    if (this.clicked === 'startYearVal') {
      this.startyear = this.startYearVal;
    }
    if (this.clicked === 'endMonthVal' && this.months.indexOf(this.endMonthVal) <= this.months.indexOf(this.startMonthVal)) {
      if (!this.temp) {
        this.temp = true;
        this.startyear = this.startYearVal;
        this.startyear++;
    }
    } else if (this.clicked === 'endMonthVal') {
      if (this.currentDate.getFullYear() !== this.startYearVal) {
        this.temp = false;
        this.startyear = this.startYearVal;
        this.startyear--;
      } 
    }

    if (this.clicked === 'endYearVal' && this.endYearVal === this.startYearVal && this.months.indexOf(this.endMonthVal) <= this.months.indexOf(this.startMonthVal)) {
      this.endMonthVal = '';
      this.form.patchValue({
        endMonth: ''
      });
    }
    if (this.clicked.startsWith('start')) {
      this.endMonthVal = '';
      this.endYearVal = '';
      this.form.patchValue({
        endMonth: '',
        endYear: ''
      });
    }
  }

  get years() {
    let yearArray = [];
    let startyear = this.startyear;
    for (let i = 1; i <= 30; i++) {
      yearArray.push(startyear++);
    }
    return yearArray;
  }

}
