import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject,APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/components/header/header.component';
import { MaterialModule } from './material.module';
import { BellowheaderComponent } from './shared/components/bellowheader/bellowheader.component';
import { MenuListItemComponent } from './shared/components/menu-list-item/menu-list-item.component';
import { HiddenOverlayComponent } from './shared/components/hidden-overlay/hidden-overlay.component';
import { DatePipe } from '@angular/common';
import { 
  AuthGuardService as AuthGuard 
} from './core/guards/auth-guard.service';
import { InternetUnavailableComponent } from './shared/components/internet-unavailable/internet-unavailable.component';
import { MyHttpInterceptor } from './app.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Configuration } from 'msal';
import { MsalModule, MsalInterceptor, MsalAngularConfiguration, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';
import { AppConfig } from './core/app.config';

// export function initConfig(appconfig: AppConfig) {
//   return () => appconfig.load();
// }
export function initConfig(config: AppConfig): () => Promise<any> {
  return () => config.load();
}
export function MSALConfigFactory(config: AppConfig): Configuration{
  const auth = {
    auth: {
      clientId: config.getConfig("clientID"),
      authority: 'https://login.microsoftonline.com/5e007b6c-258b-4fde-adc1-8bf8a135885d',
      validateAuthority: false,
      redirectUri: config.getConfig("redirectUri"),
    },
    cache: {
      cacheLocation: "localStorage",
    }

  }
  return (auth as Configuration);
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    //popUp: !isIE,
    consentScopes: [
      "user.read",
      "openid",
      "profile",
      "api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user"
    ],
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    extraQueryParameters: {}
  };

}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BellowheaderComponent,
    MenuListItemComponent,
    HiddenOverlayComponent,
    InternetUnavailableComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MaterialModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule,
    // MsalModule.forRoot({
    //   clientID: environment.clientID,
    //   authority: `${environment.authority}${environment.tenentID}`,
    //   redirectUri: environment.redirectUri,
    //   validateAuthority : false,
    // }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    DatePipe,
    AuthGuard,
    AppConfig,
    MsalService,
     { provide: HTTP_INTERCEPTORS, useClass: MyHttpInterceptor, multi: true },
     { provide: APP_INITIALIZER, useFactory: initConfig, multi: true, deps: [AppConfig]},
     {
       provide: MSAL_CONFIG,
       useFactory: MSALConfigFactory,
       deps: [AppConfig],
     },
     {
       provide: MSAL_CONFIG_ANGULAR,
       useFactory: MSALAngularConfigFactory,
       deps: [AppConfig],
     }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              @Inject(APP_ID) private appId: string,
              public translate: TranslateService) {
              translate.setDefaultLang('en');
              translate.use('en');
  }
 }
