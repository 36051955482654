import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService} from "@azure/msal-angular";
import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public showDropDown = false;
  public headerTitle = '';
  public subscription;
  public userName = '';
  constructor(
    private utilityService: UtilityService,
    private router: Router,
    private authService : MsalService,

  ) { }

  ngOnInit() {
    this.utilityService.title.subscribe((val: string) => {
      if (val !== null)
      this.headerTitle = val;
  });
  this.utilityService.loadUserName();
  this.utilityService.loadUserRole();
  this.utilityService.userName.subscribe(val => {
    this.userName = val;
  });

  this.subscription = this.utilityService.showHiddenOverlay.subscribe(val => {
    if (!val) {
      this.showDropDown = false;
    }
  });
  }

  getFirstLetter(val) {
    if (val.length === 0) return
    return val.charAt(0).toUpperCase();
  }

  navigateTo(route:string) {
    this.router.navigate([route]);
  }

  userClickHandler() {
    this.showDropDown = !this.showDropDown;
    // this.utilityService.setHiddenOverlay(true);
  }

  logout() {
    localStorage.clear();
    this.authService.logout();
    this.showDropDown = false;
    this.utilityService.setHiddenOverlay(false);
    this.utilityService.setIsLoginPage(true);
    this.router.navigate(['login']);
  }
}
