import { Injectable } from '@angular/core';
import { Router, CanLoad, Route } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UtilityService } from '../services/utility.service';
@Injectable()
export class AuthGuardService implements CanLoad {
  constructor(public auth: AuthService, 
              public router: Router,
              public utilityService: UtilityService) {}
  canLoad(route: Route): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    const roleId = this.utilityService.getUserRoleId();
    if (route.data.expectedRole.findIndex(e => e === roleId) === -1) {
      if (roleId == '1') {
        this.router.navigate(['usermanagement']); 
      } else if (roleId == '2') {
        this.router.navigate(['vehicleList']);        
      } else if (roleId == '3') {
        this.router.navigate(['approvalList']);        
      }
      return false;
    }
    return true;
  }
}