import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { map, switchMap, catchError } from 'rxjs/operators';
// import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Subject, Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfig } from '../../core/app.config';
export const apiNotifier = new Subject();
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  error: any = {};
  authorization: any;
  apiHeaders: any;
  categories: any;
  adToken: any;
  
  blob: Blob;
  fileName: string;
  imagePath: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private appConfig: AppConfig
  ) {

   }
  apiUrl = this.appConfig.getConfig("apiUrl");
  imageRenderUrl = this.appConfig.getConfig("imageRenderUrl");
  headers = (url?) => {
    this.authorization = localStorage.getItem('token');
    this.adToken = localStorage.getItem('ADToken');
    this.apiHeaders = new HttpHeaders();
    this.apiHeaders.headers['Accept'] = 'application/json, text/plain, */*';
    // this.apiHeaders.headers['X-REQUEST-TYPE'] = 'web';
    if (url !== 'userManagement/userLogin' && this.authorization) {
      this.apiHeaders.headers['Authorization'] = 'Bearer ' + this.authorization;
    }
    else if(url == 'userManagement/userLogin' && this.adToken){
      // this.apiHeaders.headers['Authorization'] = this.adToken;
      this.apiHeaders.headers['AdToken']= this.adToken;
    }
    return this.apiHeaders;
  }
  callGetAPI(url: string) {
    // console.log('App_URL', this.apiUrl)
    return this.http.get<any>(this.apiUrl + url, this.headers())
      .pipe(map(
        data => {
          return data;
        }
      ));
  }
  callGetFileAPI(url: string) {
    return this.http.get<any>(url, this.headers())
      .pipe(map(
        data => {
          return data;
        }
      ));
  }
  callPostAPI(url: string, parameters: any) {
    return this.http.post<any>(this.apiUrl + url, parameters, this.headers(url))
      .pipe(map(
        data => {
          return data;
        }
      ));
  }
  callPutAPI(url: string, parameters: any) {
    return this.http.put<any>(this.apiUrl + url, parameters, this.headers())
      .pipe(map(
        data => {
          return data;
        }
      ));
  }
  callDeleteAPI(url: string) {
    return this.http.delete<any>(this.apiUrl + url, this.headers())
      .pipe(map(
        data => {
          return data;
        }
      ));
  }

  callFileToStorage(url: string, documentCategory: string, file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    formdata.append('documentCategory', documentCategory);
    const req = new HttpRequest('POST', this.apiUrl + url, formdata, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }
  
  successResponse(data, url = null) {
    if (data.statusCode === 200 && data.error === false) {
      if (url) {
        this.router.navigate([url.redirect]);
      }
    }
  }

renderImage(imageData, fileType = '') {
    const image = imageData ? imageData.split("mtntdocumentcontainer/") : [];
    const imageObject = {
      rootobject: 'mtntdocumentcontainer' || '',
      filepath: image[1] || ''
    };
    console.log("Image", imageObject)
    this.fileName = image[1];
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authorization
      })
    };
    if (imageObject.rootobject && imageObject.filepath) {
      return this.http.post<Blob>(this.imageRenderUrl, imageObject, httpOptions).pipe(
        map(imgData => {
          if (fileType === 'pdf') {
            this.imagePath = imageData;
            return this.createPDFFileFromBlob(imgData as Blob);
          } else {
            this.imagePath = imageData;
            return this.createImageFromBlob(imgData as Blob, fileType);
          }
        }),
        // catchError(error => this.errorHandler.handleError(error, 'Render Image'))
        );
    } else {
      const imageObj = {
        imageURL: '', imagePath: ''
      };
      return of(imageObj);
    }
  }

  createPDFFileFromBlob(response: Blob) {
    this.blob = new Blob([response], { type: 'application/pdf' });
    const downloadURL = window.URL.createObjectURL(this.blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${this.fileName}`;
    link.click();
  }

  createImageFromBlob(image: Blob, fileType = '') {
    if (fileType === 'video') {
      this.blob = new Blob([image], { type: 'video/mp4' });
    } else {
      this.blob = image;
    }
    const imageURL = URL.createObjectURL(this.blob);
    const imageObj = {
      imageURL: this.domSanitizer.bypassSecurityTrustUrl(imageURL),
      imagePath: this.imagePath,
      fileType: fileType
    };
    return image ? imageObj : {};
  }

}
