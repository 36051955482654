import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class AppConfig {

  private config: Object = null;
  private env:    Object = null;

  constructor(private http: HttpClient) {
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json') test
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get('./assets/env/env.json')
      .pipe( 
        map( res => res )
      )
      .subscribe( (envResponse) => {
        this.env = envResponse;
        let request: any = null;
        if(envResponse['env'])
        request = this.http.get('./assets/config/env.' + envResponse['env'] + '.json');
        if (request) {
          request.pipe(
              map( res => res )
            )
            .subscribe((responseData: any) => { 
                this.config = responseData;
                resolve(true);
            });
        } else {
          console.error('Env config file "env.json" is not valid');
          resolve(true);
        }
      });

    });
  }
}
