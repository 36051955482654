import { Component, OnInit, HostBinding, Input } from '@angular/core';
import {Router} from '@angular/router';
import { UtilityService } from '../../../core/services/utility.service';


@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss']
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item:{
    route: any;
    img: any;
    name: any;
  };

  constructor(public utilityService: UtilityService,
              public router: Router
              ) {
  }

  ngOnInit() {

  }

  onItemSelected(route: string) {  
    this.navigateTo(route);
    this.utilityService.closeNav();

  }

  navigateTo(navigateurl: string) {
    this.router.navigate([navigateurl]);
  }

}
